<template>
  <div>
    <v-card
        max-width="400"
        class="mx-auto"
    >
      <v-app-bar
          dark
          color="#455A64"
      >
        <v-toolbar-title>{{ $t('newsAndEvents') }}</v-toolbar-title>
      </v-app-bar>
      <v-container>
        <v-row dense>
          <v-col
              v-for="(item, i) in items"
              :key="i"
              cols="12"
              @click="gotoDetail(item)"
              style="cursor: pointer"
          >
            <v-card
                :color="item.color"
                dark
            >
              <div class="d-flex flex-no-wrap justify-space-between">
                <div class="hover02 columnH">
                  <div>
                    <figure>
                      <img width="100px;" height="130px" :src="item.url"/>
                    </figure>
                  </div>
                </div>
                <div>
                  <v-card-title
                      class="text-h7"
                      v-text="item.title.substr(0,20)"
                  ></v-card-title>
                  <v-card-subtitle style="text-align: left" v-text="item.body.substr(0,50)"></v-card-subtitle>
                  <div style="font-size: 8px;float: right;color: yellow">{{ item.date | formatDate }}</div>
                </div>
              </div>

            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-card>

  </div>
</template>
<script>
import MyMixin from "@/mixins/mixin"

export default {
  name: "SideBarEvent",
  mixins: [MyMixin],
  data() {
    return {
      items: []
    }
  },
  methods: {
    gotoDetail(doc) {
      this.$router.push({name: 'NewsEventsDetail', params: {doc: doc}});
    }
  },
  created() {
    this.items = this.getNews().slice(0, 7);

  }
}

</script>


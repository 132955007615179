<template>
  <div>
    <v-container>
      <h1 style="text-align: left;margin-bottom: 20px">{{$t('presidentWelcome')}}</h1>

      <v-row>
        <v-col cols="12" sm="8" md="8">

        </v-col>

        <v-col cols="12" sm="4" md="4" v-if="!$vuetify.breakpoint.mobile">
          <side-bar-event></side-bar-event>
          <media></media>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import SideBarEvent from "@/components/sidebarEvent";
import Media from "@/components/sidebarMedia";

  export default {
    name: 'RectorWelcome',
    components: {Media,SideBarEvent},
    data(){
      return{


      }
    }
  }
</script>

<template>
  <div>
    <v-card
        max-width="400"
        class="mx-auto"
    >
      <v-app-bar
          dark
          color="#455A64"
      >
        <v-toolbar-title>{{ $t('media') }}</v-toolbar-title>
      </v-app-bar>
      <v-container>
        <v-row dense>
          <v-col
              v-for="(item, i) in items"
              :key="i"
              cols="12"
              @click="gotoDetail(item)"
              style="cursor: pointer"
          >
            <v-card
                :color="item.color"
                dark
            >
              <div class="d-flex flex-no-wrap justify-space-between">
                <div>
                  <v-avatar
                      class="ma-3"
                      size="125"
                      tile
                  >
                    <v-img :src="item.urlList[0]"></v-img>

                  </v-avatar>

                </div>
                <div>
                  <v-card-title
                      style="text-align: left;word-break: break-word"
                      class="text-h7">
                    {{ item.title.substr(0,40) }}
                  </v-card-title>


                </div>

              </div>

              <v-card-subtitle style="text-align: justify">{{ item.body.substr(0, 120) }}...</v-card-subtitle>
              <div style="font-size: 8px;color: yellow;text-align: right">{{ item.date | formatDate }}</div>

            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-card>

  </div>
</template>
<script>
import MyMixin from "@/mixins/mixin"

export default {
  name: "Media",
  mixins: [MyMixin],

  data() {
    return {
      items: [],
    }
  },
  methods: {
    gotoDetail(doc) {
      this.$router.push({name: 'NewsEventsDetail', params: {doc: doc}});
    }
  },
  created() {
    this.items = this.getMedia().slice(0, 7);
  },

}

</script>